import React, { useEffect, useRef, useState } from "react";
import S from "../styles/Terminal.module.scss";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  addCommandLine,
  addTypedResponse,
  blankLine,
  employers,
} from "../utils/terminal";

const Terminal = () => {
  const [currentCommand, setCurrentCommand] = useState<string>("");
  const [fadeOut, setfadeOut] = useState(false);
  const commandEntered = useRef<string>("");
  const terminalRef = useRef<HTMLDivElement>(null);
  const currentInput = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    let delay: number = 0;
    setTimeout(() => {
      onLoadCommands.forEach((command, index) => {
        setTimeout(() => {
          addCommandLine(terminalRef, command.command);
        }, delay);
        command.delay && (delay += command.delay);
      });
    }, 1000);
  }, []);

  useEffect(() => {
    commandEntered.current = currentCommand;
    return () => {
      commandEntered.current = "";
    };
  }, [currentCommand]);

  const createInput = (prompt?: string): JSX.Element => {
    return (
      <div>
        {prompt != null ? `${prompt} ` : "$ "}
        <input
          type="text"
          ref={currentInput}
          onChange={(event) => {
            setCurrentCommand(event.target.value);
          }}
          onKeyDown={handleCommand}
          autoFocus
        />
      </div>
    );
  };

  const handleCommand = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const username: string = commandEntered.current;
    if (username !== "" && event.key === "Enter" && currentInput.current) {
      currentInput.current.setAttribute("disabled", "disabled");
      blankLine(terminalRef);
      await addTypedResponse(
        terminalRef,
        `Hello ${username}, welcome to my site!`
      );
      if (employers.has(username.toLowerCase())) {
        await addTypedResponse(
          terminalRef,
          "As a potential employer, I'm excited to showcase my skills and experience to you."
        );
      }
      setfadeOut(true);
      setTimeout(() => {
        return navigate("/welcome");
      }, 1500);
    }
  };

  interface onLoadCommandsProps {
    command: JSX.Element;
    delay?: number;
  }
  const onLoadCommands: onLoadCommandsProps[] = [
    { command: <>* DNS request received...</>, delay: 500 },
    { command: <>* Fetching IP address...</>, delay: 500 },
    { command: <>* Exchanging packets...</>, delay: 1000 },
    { command: <>* Establishing secure connection...</>, delay: 1000 },
    { command: <>* Sending HTTP request...</>, delay: 500 },
    { command: <>* Waiting for server response...</>, delay: 500 },
    { command: <>* Rendering content...</>, delay: 500 },
    {
      command: (
        <div style={{ opacity: 0, userSelect: "none" }}>Yufudtehde ert</div>
      ),
      delay: 0,
    },
    {
      command: createInput("What is your name:"),
      delay: 0,
    },
  ];

  return (
    <>
      <Helmet>
        <title>Terminal</title>
      </Helmet>
      <div
        ref={terminalRef}
        className={`${S.content} ${fadeOut && "terminal-fade-exit-active"}`}
      ></div>
    </>
  );
};

export default Terminal;
