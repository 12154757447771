import { createRoot } from "react-dom/client";

export const addCommandLine = (
  ref: React.RefObject<HTMLDivElement>,
  content: JSX.Element
): void => {
  const commandLine = document.createElement("div");
  ref.current && ref.current.appendChild(commandLine);
  createRoot(commandLine).render(content);
};

export const addTypedResponse = async (
  ref: React.RefObject<HTMLDivElement>,
  content: string
): Promise<void> => {
  return new Promise<void>((resolve) => {
    let i: number = 0;
    let speed: number = 70;
    const commandLine = document.createElement("div");
    ref.current && ref.current.appendChild(commandLine);

    const addChar = () => {
      if (i < content.length) {
        commandLine.innerHTML += content.charAt(i);
        i++;
        setTimeout(addChar, speed);
      }
    };

    addChar();
    setTimeout(() => {
      resolve();
    }, content.length * speed + 1000);
  });
};

export const blankLine = (ref: React.RefObject<HTMLDivElement>): void => {
  const commandLine = document.createElement("div");
  createRoot(commandLine).render(
    <div style={{ opacity: 0, userSelect: "none" }}>o on h idnsce!</div>
  );
  ref.current && ref.current.appendChild(commandLine);
};

export const employers = new Set<string>([
  "intuit",
  "ibm",
  "keysight technologies",
  "csiro",
  "dxc technology",
]);
